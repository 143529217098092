import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Seo from '../components/seo';
import Image from '../components/image';
import CustomFetch from '../components/fetch';
import CustomMessage from '../components/message';
import validator from 'validator';
import { Mention } from 'akar-icons';

const ForgotPasswordRequestPage = () => {
    const [data, setData] = useState({
        email: '',
    });
    const [dataClass, setDataClass] = useState({
        email: 'login__input',
    });
    const [messageSetting, setMessageSetting] = useState({
        showMessage: false,
        messageClass: 'dialog dialog--error',
        messageContent: '',
    });

    const handleChange = (e) => {
        if (e) {
            setData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));

            if (dataClass[e.target.name] === 'login__input inputerror') {
                setDataClass((prevState) => ({
                    ...prevState,
                    [e.target.name]: 'login__input',
                }));

                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: false,
                }));
            }
        }
    };

    const handleForgot = (e) => {
        if (e) {
            e.preventDefault();

            let _error = false;

            if (data.email === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    email: 'login__input inputerror',
                }));

                _error = true;
            } else {
                if (validator.isEmail(data.email) === false) {
                    setDataClass((prevState) => ({
                        ...prevState,
                        email: 'login__input inputerror',
                    }));

                    setMessageSetting((prevState) => ({
                        ...prevState,
                        showMessage: true,
                        messageClass: 'dialog dialog--error',
                        messageContent: 'Please use a valid email address.',
                    }));

                    _error = true;
                }
            }

            if (_error === false) {
                CustomFetch(
                    'password-reset-request',
                    'POST',
                    data,
                    function (result) {
                        if (result.error === '') {
                            navigate('/password-reset', {
                                state: {
                                    email: data.email,
                                },
                            });
                        } else {
                            setMessageSetting((prevState) => ({
                                ...prevState,
                                showMessage: true,
                                messageClass: 'dialog dialog--error',
                                messageContent: String(result.error),
                            }));
                        }
                    },
                    function (error) {
                        setMessageSetting((prevState) => ({
                            ...prevState,
                            showMessage: true,
                            messageClass: 'dialog dialog--error',
                            messageContent: String(error),
                        }));
                    }
                );
            }
        }
    };

    return (
        <div id="app">
            <Seo
                title="Forgot Password Request"
                description="Dawn Projects Ticket System"
            />
            <CustomMessage data={messageSetting} />
            <div className="landing">
                <div className="landing__title">
                    <Image src="dawn-projects-logo.svg" alt="Dawn Projects" />
                    <h1>Ticket System</h1>
                </div>
            </div>
            <div className="login">
                <div className="login__box">
                    <div className="formitem fw">
                        <label className="fi__label">
                            <input
                                name="email"
                                className={dataClass.email}
                                type="text"
                                placeholder=" "
                                value={data.email}
                                onChange={handleChange}
                            />
                            <span className="fi__span">Email Address</span>
                            <Mention size={24} />
                        </label>
                    </div>
                    <div className="formitem fw">
                        <button onClick={handleForgot} className="loginbtn">
                            Forgot Password Request
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordRequestPage;
